// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-about-text-js": () => import("./../../../src/pages/about-me/about-text.js" /* webpackChunkName: "component---src-pages-about-me-about-text-js" */),
  "component---src-pages-about-me-books-js": () => import("./../../../src/pages/about-me/books.js" /* webpackChunkName: "component---src-pages-about-me-books-js" */),
  "component---src-pages-about-me-credits-js": () => import("./../../../src/pages/about-me/credits.js" /* webpackChunkName: "component---src-pages-about-me-credits-js" */),
  "component---src-pages-about-me-js": () => import("./../../../src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-about-me-photo-profile-js": () => import("./../../../src/pages/about-me/photo-profile.js" /* webpackChunkName: "component---src-pages-about-me-photo-profile-js" */),
  "component---src-pages-blog-list-jsx": () => import("./../../../src/pages/blog-list.jsx" /* webpackChunkName: "component---src-pages-blog-list-jsx" */),
  "component---src-pages-blog-post-js": () => import("./../../../src/pages/blog-post.js" /* webpackChunkName: "component---src-pages-blog-post-js" */),
  "component---src-pages-contact-form-js": () => import("./../../../src/pages/contact/form.js" /* webpackChunkName: "component---src-pages-contact-form-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-social-media-js": () => import("./../../../src/pages/contact/social-media.js" /* webpackChunkName: "component---src-pages-contact-social-media-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */)
}

